import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/paysafe/profile')
    },
    save (profile) { // Création d'une nouvelle entrée
        return api().post('/paysafe/profile', profile)
    },
    view (profileId) { // Voir une entrée spécifique
        return api().get(`/paysafe/profile/${profileId}`)
    },
    update (profile, profileId) { // Mise à jour d'une entrée spécifique
        return api().put(`/paysafe/profile/${profileId}`, profile)
    },
    remove (profileId) { // Suppression d'une entrée spécifique
        return api().delete(`/paysafe/profile/${profileId}`)
    },
}
