import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/paysafe/profile/card')
    },
    save (profileCard, profileId, clientId) { // Création d'une nouvelle entrée
        return api().post(`/paysafe/profile/card/create/${profileId}/${clientId}`, profileCard)
    },
    view (profileCardId, profileId) { // Voir une entrée spécifique
        return api().get(`/paysafe/profile/card/${profileCardId}/${profileId}`)
    },
    update (profileCard, profileCardId, profileId, clientId) { // Mise à jour d'une entrée spécifique
        return api().put(`/paysafe/profile/card/${profileCardId}/${profileId}/${clientId}`, profileCard)
    },
    remove (profileCardId, profileId, clientId) { // Suppression d'une entrée spécifique
        return api().delete(`/paysafe/profile/card/${profileCardId}/${profileId}/${clientId}`)
    },
    validation (cardValidation) { // Création d'une nouvelle entrée
        return api().post('/paysafe/profile/card/validation', cardValidation)
    },
}
