<template>
  <v-container
    v-if="submitForm"
    class="py-0"
  >
    <v-col cols="12">
      <validation-observer
        ref="obs"
        v-slot="{ handleSubmit }"
      >
        <base-material-card
          inline
          icon="mdi-account-outline"
          :title="$t('client.Client_Information')"
          class="px-5 py-3"
        >
          <form>
            <v-subheader class="display-1 mt-3">
              Information
            </v-subheader>
            <div
              v-if="!editForm"
              class="text-right"
            >
              <v-btn
                color="success"
                @click="editForm = true"
              >
                {{ $t('Edit') }}
              </v-btn>
            </div>

            <!--  Username-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.Username') }}</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.Username') }}:</span>
              </v-col>

              <v-col cols="8">
                <!--  Editable-->
                <v-text-field
                  v-if="editForm"
                  v-model="submitForm.username"
                  disabled
                />
                <!--  Non Editable-->
                <span v-else>{{ submitForm.username }}</span>
              </v-col>
            </v-row>

            <!--  Sexe-->
            <!-- <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <span v-if="editForm">{{ $t('client.Sex') }}*</span>
                <span v-else>{{ $t('client.Sex') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Sex')"
                  rules="required"
                >
                  <v-select
                    v-if="editForm"
                    v-model="submitForm.sexe"
                    :items="sexes"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <span v-else>{{ submitForm.sexe }}</span>
                </validation-provider>
              </v-col>
            </v-row> -->

            <!--  Nom-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('Name') }}</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('Name') }}:</span>
              </v-col>

              <v-col cols="8">
                <!--  Editable-->
                <v-text-field
                  v-if="editForm"
                  v-model="submitForm.nom"
                  disabled
                />
                <!--  Non Editable-->
                <span v-else>{{ submitForm.nom }}</span>
              </v-col>
            </v-row>

            <!--  Prénom-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('FirstName') }}</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('FirstName') }}:</span>
              </v-col>

              <v-col cols="8">
                <!--  Editable-->
                <v-text-field
                  v-if="editForm"
                  v-model="submitForm.prenom"
                  disabled
                />
                <!--  Non Editable-->
                <span v-else>{{ submitForm.prenom }}</span>
              </v-col>
            </v-row>

            <!--  Téléphone principal-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.Primary_phone') }}</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.Primary_phone') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Primary_phone')"
                  :rules="{ required: false, regex: regexPhoneNumber }"
                >
                  <!--  Editable-->
                  <v-text-field
                    v-if="editForm"
                    v-model="submitForm.telephone1"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.telephone1 | phoneNumberFormat }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Poste-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('profile.Extension') }}</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('profile.Extension') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('profile.Extension')"
                  :rules="{ required: false, numeric:true }"
                >
                  <!--  Editable-->
                  <v-text-field
                    v-if="editForm"
                    v-model="submitForm.telephone2"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.telephone2 }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Courriel-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.Email') }}*</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.Email') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Email')"
                  rules="required|email"
                >
                  <!--  Editable-->
                  <v-text-field
                    v-if="editForm"
                    v-model="submitForm.email"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.email }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Langue-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.Language') }}*</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.Language') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Language')"
                  :rules="{ required: true }"
                >
                  <!--  Editable-->
                  <v-select
                    v-if="editForm"
                    v-model="submitForm.langue"
                    :placeholder="$t('profile.Choose_language')"
                    :items="language"
                    item-text="item"
                    item-value="valeur"
                    persistent-hint
                    single-line
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ getLanguage }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Mot (Champs requis)-->
            <v-col
              v-if="editForm"
              cols="2"
            >
              <div class="body-2 font-weight-light">
                *{{ $t('Required_Fields') }}
              </div>
            </v-col>

            <div
              v-if="editForm"
              class="pa-3 text-center"
            >
              <!--  Bouton annuler-->
              <v-btn
                color="error"
                @click="cancel"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <!--  Bouton submit-->
              <v-btn
                color="success"
                class="ml-3"
                @click="handleSubmit(submit)"
              >
                {{ $t('Validate') }}
              </v-btn>
            </div>
          </form>

          <!--  Paiement automatisé-->
          <!-- Afficher si il y a une adresse-->
          <template v-if="!editForm && !showAutomaticPaiement">
            <v-subheader
              class="display-1 mt-3"
            >
              {{ $t('payment.Automated_payment') }}
              <small
                v-if="!paySafeProfile.address.length"
                class="red--text ml-3"
              >({{ $t('profile.automatic_payment.Add_card_address_message') }})</small>
            </v-subheader>

            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              />

              <v-col cols="8">
                <!-- Address-->
                <div>
                  <span
                    class="display-1 text-decoration-underline"
                  >
                    {{ $t('client.Address') }}
                  </span>

                  <!-- Address-->
                  <v-row
                    v-if="paySafeProfile.address.length"
                    dense
                  >
                    <v-col
                      cols="12"
                    >
                      <v-card>
                        <v-list two-line>
                          <v-list-item-group>
                            <template v-for="(address, index) in paySafeProfile.address">
                              <v-list-item :key="address.id">
                                <v-list-item-content>
                                  <v-list-item-title class="text-h3 mb-1">
                                    {{ address.nickName }}
                                  </v-list-item-title>

                                  <!-- Adresse-->
                                  <v-list-item-subtitle
                                    class="text--primary"
                                    v-text="address.street"
                                  />

                                  <!-- Province-->
                                  <v-list-item-subtitle
                                    class="text--primary"
                                    v-text="address.city"
                                  />

                                  <!-- Pays-->
                                  <v-list-item-subtitle
                                    class="text--primary"
                                    v-text="`${address.state}, ${address.country}`"
                                  />

                                  <!-- zip-->
                                  <v-list-item-subtitle
                                    class="text--primary"
                                    v-text="address.zip"
                                  />

                                  <!-- Adresse par défaut-->
                                  <v-list-item-subtitle
                                    v-if="address.defaultShippingAddressIndicator"
                                    class="primary--text"
                                  >
                                    {{ $t('profile.automatic_payment.Default_Address') }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-card-actions>
                                  <v-btn
                                    rounded
                                    text
                                    color="green"
                                    @click="editAddress(address)"
                                  >
                                    {{ $t('Edit') }}
                                  </v-btn>

                                  <v-btn
                                    rounded
                                    text
                                    color="primary"
                                    @click="deleteAddress(address)"
                                  >
                                    {{ $t('Delete') }}
                                  </v-btn>
                                </v-card-actions>
                              </v-list-item>

                              <v-divider
                                v-if="index < paySafeProfile.address.length - 1"
                                :key="index"
                              />
                            </template>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-btn
                        small
                        class="mt-3"
                        color="success"
                        @click="addNewAddress"
                      >
                        {{ $t('profile.automatic_payment.Add_address') }}
                        <v-icon
                          right
                        >
                          mdi-map-marker
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>

                <!-- Carte-->
                <!-- On affiche si il y a une adresse-->
                <div
                  v-if="paySafeProfile.address.length"
                  class="mt-4"
                >
                  <span
                    class="display-1 text-decoration-underline"
                  >
                    {{ $t('order.Credit_card') }}
                  </span>
                  <!-- Card-->
                  <v-row
                    dense
                  >
                    <v-col
                      v-for="card in paySafeProfile.cards"
                      :key="card.id"
                      cols="6"
                    >
                      <v-card
                        class="mx-auto"
                        max-width="344"
                        outlined
                      >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <!-- Type de carte-->
                            <v-list-item-title class="text-h2 mb-1">
                              {{ cardType[card.cardType] }}
                            </v-list-item-title>

                            <!-- HolderName-->
                            <v-list-item-title class="text-h4 mb-1">
                              {{ card.holderName }}
                            </v-list-item-title>

                            <!-- Dernier numéro-->
                            <v-list-item-title>{{ $t('profile.automatic_payment.Credit_card_ending') }} {{ card.lastDigits }}</v-list-item-title>

                            <!-- Expiration-->
                            <v-list-item-subtitle
                              v-if="ifCardExpired(card.cardExpiry)"
                              class="mt-1"
                            >
                              Expiration: {{ card.cardExpiry.month.toString().padStart(2, '0') }}/{{ card.cardExpiry.year }}
                              <v-tooltip
                                top
                              >
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    color="yellow accent-3"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-alert
                                  </v-icon>
                                </template>
                                <span>{{ $t('profile.automatic_payment.Expired_card') }}</span>
                              </v-tooltip>
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-icon>
                            <v-icon color="green">
                              mdi-credit-card
                            </v-icon>
                          </v-list-item-icon>
                          <!-- <v-list-item-avatar
                            tile
                            size="80"
                          >
                            <v-img :src="card.src" />
                          </v-list-item-avatar> -->
                        </v-list-item>

                        <v-card-actions>
                          <v-btn
                            rounded
                            text
                            color="green"
                            @click="editCard(card)"
                          >
                            {{ $t('Edit') }}
                          </v-btn>

                          <v-btn
                            rounded
                            text
                            color="primary"
                            @click="deleteCard(card)"
                          >
                            {{ $t('Delete') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Ajouter une carte-->
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-btn
                        small
                        class="mt-3"
                        color="success"
                        @click="createNewCard"
                      >
                        {{ $t('profile.automatic_payment.Add_card') }}
                        <v-icon
                          right
                        >
                          mdi-credit-card-plus-outline
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </template>
        </base-material-card>
      </validation-observer>
    </v-col>

    <!--  Dialog pour ajouter/modifier une carte-->
    <v-dialog
      v-model="showAddEditCardDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="showAddEditCardDialog = !showAddEditCardDialog"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              class="body-1 text-center"
              cols="12"
            >
              <v-icon
                size="78px"
                color="success"
              >
                mdi-credit-card-outline
              </v-icon>

              <h2 class="mt-3">
                {{ $t('profile.automatic_payment.name') }}
              </h2>

              <div class="mt-3">
                <p v-html="cardSelected.id ? `${$t('profile.automatic_payment.message_editCard')} ....${cardSelected.lastDigits}` : $t('profile.automatic_payment.message_newCard')" />
              </div>
            </v-col>
          </v-row>

          <!-- Bouton inscription-->
          <validation-observer
            ref="obsNewCreditCard"
            v-slot="{ handleSubmit }"
          >
            <form>
              <!-- Address-->
              <v-row
                justify="center"
                dense
              >
                <v-col cols="10">
                  <v-row
                    justify="center"
                    dense
                  >
                    <!-- year-->
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        :name="$t('profile.automatic_payment.Billind_address')"
                        rules="required"
                      >
                        <v-select
                          v-model="cardSelected.billingAddressId"
                          :items="paySafeProfile.address"
                          item-text="nickName"
                          item-value="id"
                          :label="$t('profile.automatic_payment.Billind_address')"
                          :error-messages="errors"
                          :success="valid"
                          required
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Si c'est une édition, le client doit cliquer sur le checkbox pour changer sa carte-->
              <v-row
                v-if="cardSelected.id"
                justify="center"
                dense
              >
                <v-col cols="10">
                  <v-checkbox
                    v-model="editCreateCardChange"
                    :label="$t('profile.automatic_payment.Modify_card')"
                    @change="changeEditCard"
                  />
                </v-col>
              </v-row>

              <!-- Ajout ou modification de la carte-->
              <template v-if="editCreateCardChange">
                <!-- Nom-->
                <v-row
                  justify="center"
                  dense
                >
                  <v-col cols="10">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      :name="$t('profile.automatic_payment.HolderName')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="cardSelected.holderName"
                        :label="$t('profile.automatic_payment.HolderName')"
                        :error-messages="errors"
                        :success="valid"
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <!-- Numéro de carte-->
                <v-row
                  justify="center"
                  dense
                >
                  <v-col cols="10">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      :name="$t('profile.automatic_payment.No_credit_card')"
                      :rules="{ required: true, min: 15, max: 16, numeric: true }"
                    >
                      <v-text-field
                        v-model="cardSelected.cardNum"
                        maxlength="16"
                        :label="$t('profile.automatic_payment.No_credit_card')"
                        :error-messages="errors"
                        :success="valid"
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <!-- Expiration-->
                <v-row
                  justify="center"
                  dense
                >
                  <v-col cols="10">
                    <v-row
                      justify="center"
                      dense
                    >
                      <!-- Mois-->
                      <v-col
                        cols="6"
                      >
                        <validation-provider
                          v-slot="{ errors, valid }"
                          :name="$t('time.month')"
                          rules="required"
                        >
                          <v-select
                            v-model="cardSelected.cardExpiry.month"
                            :items="cardExpiration.month"
                            :label="$t('time.month')"
                            :error-messages="errors"
                            :success="valid"
                            required
                          />
                        </validation-provider>
                      </v-col>

                      <!-- year-->
                      <v-col cols="6">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          :name="$t('time.year')"
                          rules="required"
                        >
                          <v-select
                            v-model="cardSelected.cardExpiry.year"
                            :items="cardExpiration.year"
                            :label="$t('time.year')"
                            :error-messages="errors"
                            :success="valid"
                            required
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- CVV-->
                <v-row
                  justify="center"
                  dense
                >
                  <v-col cols="10">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="CVV"
                      :rules="{ required: true, min: 3, max: 4, numeric: true }"
                    >
                      <v-text-field
                        v-model="cardSelected.cvv"
                        maxlength="4"
                        label="CVV"
                        :error-messages="errors"
                        :success="valid"
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row
                  dense
                >
                  <v-col
                    offset-md="1"
                    cols="12"
                  >
                    <div class="body-2 red--text">
                      *{{ $t('profile.automatic_payment.Prepaid_card_warning') }}
                    </div>
                  </v-col>
                </v-row>
              </template>

              <!-- Aperçu de la carte (édition)-->
              <template v-else>
                <!-- Nom-->
                <v-row
                  justify="center"
                  dense
                >
                  <v-col cols="10">
                    <v-text-field
                      v-model="cardSelected.holderName"
                      disabled
                      :label="$t('profile.automatic_payment.HolderName')"
                    />
                  </v-col>
                </v-row>

                <!-- Numéro de carte-->
                <v-row
                  justify="center"
                  dense
                >
                  <v-col cols="10">
                    <v-text-field
                      :value="`....${cardSelected.lastDigits}`"
                      disabled
                      :label="$t('profile.automatic_payment.No_credit_card')"
                    />
                  </v-col>
                </v-row>

                <!-- Expiration-->
                <v-row
                  justify="center"
                  dense
                >
                  <v-col cols="10">
                    <v-row
                      justify="center"
                      dense
                    >
                      <!-- Mois-->
                      <v-col
                        cols="6"
                      >
                        <v-select
                          v-model="cardSelected.cardExpiry.month"
                          disabled
                          :items="cardExpiration.month"
                          :label="$t('time.month')"
                        />
                      </v-col>

                      <!-- year-->
                      <v-col cols="6">
                        <v-select
                          v-model="cardSelected.cardExpiry.year"
                          disabled
                          :items="cardExpiration.year"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!--  Bouton submit-->
              <div class="text-center">
                <v-btn
                  color="success"
                  class="ml-3 mt-4"
                  @click="handleSubmit(submitNewCreditCard)"
                >
                  {{ $t('Validate') }}
                </v-btn>
              </div>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Dialog pour ajouter/modifier une addresse-->
    <v-dialog
      v-model="showAddEditAddressDialog"
      max-width="900"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="showAddEditAddressDialog = !showAddEditAddressDialog"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              class="body-1 text-center"
              cols="12"
            >
              <v-icon
                size="78px"
                color="success"
              >
                mdi-credit-card-outline
              </v-icon>

              <h2 class="mt-3">
                {{ $t('profile.automatic_payment.name') }}
              </h2>

              <div class="mt-3">
                <p v-html="addressSelected.id ? `${$t('profile.automatic_payment.message_editAddress')}` : $t('profile.automatic_payment.message_newAddress')" />
              </div>
            </v-col>
          </v-row>
          <!-- Bouton inscription-->
          <validation-observer
            ref="obsNewAddress"
            v-slot="{ handleSubmit }"
          >
            <form>
              <!-- Description-->
              <v-row
                justify="center"
                dense
              >
                <v-col cols="10">
                  <v-row
                    justify="center"
                    dense
                  >
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Description"
                        rules="required"
                      >
                        <v-text-field
                          v-model="addressSelected.nickName"
                          label="Description"
                          :error-messages="errors"
                          :success="valid"
                          required
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Adresse-->
              <v-row
                justify="center"
                dense
              >
                <v-col cols="10">
                  <v-row
                    justify="center"
                    dense
                  >
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        :name="$t('client.Address')"
                        rules="required"
                      >
                        <v-text-field
                          v-model="addressSelected.street"
                          :label="$t('client.Address')"
                          :error-messages="errors"
                          :success="valid"
                          required
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Ville-->
              <v-row
                justify="center"
                dense
              >
                <v-col cols="10">
                  <v-row
                    justify="center"
                    dense
                  >
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        :name="$t('client.City')"
                        rules="required"
                      >
                        <v-text-field
                          v-model="addressSelected.city"
                          :label="$t('client.City')"
                          :error-messages="errors"
                          :success="valid"
                          required
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Province-->
              <v-row
                justify="center"
                dense
              >
                <v-col cols="10">
                  <v-row
                    justify="center"
                    dense
                  >
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Province"
                        rules="required"
                      >
                        <!--  Editable-->
                        <v-select
                          v-model="addressSelected.state"
                          :items="provincesPaysafe"
                          label="Province"
                          :error-messages="errors"
                          :success="valid"
                          required
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- ZIP-->
              <v-row
                justify="center"
                dense
              >
                <v-col cols="10">
                  <v-row
                    justify="center"
                    dense
                  >
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        :name="$t('client.ZIP_code')"
                        :rules="{ required: true, regex: regexZipCode }"
                      >
                        <v-text-field
                          v-model="addressSelected.zip"
                          upper-case-first-character
                          :label="$t('client.ZIP_code')"
                          :error-messages="errors"
                          :success="valid"
                          placeholder="H0H 0H0"
                          required
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Default Address-->
              <v-row
                justify="center"
                dense
              >
                <v-col cols="10">
                  <v-row
                    justify="center"
                    dense
                  >
                    <v-col cols="12">
                      <v-checkbox
                        v-model="addressSelected.defaultShippingAddressIndicator"
                        :label="$t('profile.automatic_payment.Default_Address')"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!--  Bouton submit-->
              <div class="text-center">
                <v-btn
                  color="success"
                  class="ml-3"
                  @click="handleSubmit(submitNewEditAddress)"
                >
                  {{ $t('Validate') }}
                </v-btn>
              </div>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // Service
  import ClientsService from '@/services/01Cell/ClientsService'
  import ContratsService from '@/services/01Cell/ContratsService'
  import PaiementsInscriptionService from '@/services/01Cell/PaiementsInscriptionService'
  import PaysafeProfileService from '@/services/PAYSAFE/PaysafeProfileService'
  import PaysafeProfileAddressService from '@/services/PAYSAFE/PaysafeProfileAddressService'
  import PaysafeProfileCardService from '@/services/PAYSAFE/PaysafeProfileCardService'
  // Mixins
  import { ClientEdit } from '@/mixins/Client/ClientEdit'
  // Other
  import Swal from 'sweetalert2'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'Profile',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [ClientEdit],
    data () {
      return {
        showAutomaticPaiement: true,
        submitForm: null,
        paySafeProfile: {
          profile: null,
          addressDefault: {},
          address: [],
          cards: [],
        },
        editForm: false,
        showAddEditCardDialog: false,
        showAddEditAddressDialog: false,
        language: [
          {
            item: this.$i18n.t('French'),
            valeur: 'fr',
          },
          {
            item: this.$i18n.t('English'),
            valeur: 'en',
          },
        ],
        cardSelected: {
          holderName: null,
          cardExpiry: {
            month: null,
            year: null,
          },
          cvv: null,
        },
        editCreateCardChange: false,
        provincesPaysafe: [
          { value: 'AB', text: this.$i18n.t('client.province.Alberta') },
          { value: 'BC', text: this.$i18n.t('client.province.British_Columbia') },
          { value: 'PE', text: this.$i18n.t('client.province.Prince_Edward_Island') },
          { value: 'MB', text: this.$i18n.t('client.province.Manitoba') },
          { value: 'NB', text: this.$i18n.t('client.province.New_Brunswick') },
          { value: 'NS', text: this.$i18n.t('client.province.Nova_Scotia') },
          { value: 'NU', text: this.$i18n.t('client.province.Nunavut') },
          { value: 'ON', text: this.$i18n.t('client.province.Ontario') },
          { value: 'QC', text: this.$i18n.t('client.province.Quebec') },
          { value: 'SK', text: this.$i18n.t('client.province.Saskatchewan') },
          { value: 'NL', text: this.$i18n.t('client.province.Newfoundland_and_Labrador') },
          { value: 'NT', text: this.$i18n.t('client.province.Northwest_Territories') },
          { value: 'YT', text: this.$i18n.t('client.province.Yukon') },
        ],
        cardExpiration: {
          month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          year: [],
        },
        cardType: {
          VI: 'Visa',
          AM: 'American Express',
          DI: 'Discover',
          MC: 'MasterCard',
          DC: 'A définir',
        },
        addressSelected: {
          city: null,
          country: null,
          defaultShippingAddressIndicator: null,
          nickName: null,
          state: null,
          status: null,
          street: null,
          zip: null,
        },
      }
    },

    computed: {
      ...mapState(['userInfo']),
      getLanguage () {
        if (this.submitForm.langue === 'fr') return this.$i18n.t('French')
        if (this.submitForm.langue === 'en') return this.$i18n.t('English')
        return this.submitForm.langue
      },
    },

    watch: {
      // UpperCase ZIP
      'paySafeProfile.addressDefault.zip' (val) {
        if (val) this.paySafeProfile.addressDefault.zip = val.toUpperCase()
      },
      'addressSelected.zip' (val) {
        if (val) this.addressSelected.zip = val.toUpperCase()
      },
    },

    async mounted () {
      // Array pour les années d'expiration pour une nouvelle carte
      const d = new Date()
      const year = d.getFullYear()
      for (let index = 0; index < 7; index++) {
        this.cardExpiration.year.push(year + index)
      }

      this.getData(false)
    },

    methods: {
      ...mapMutations([
        'resetNewCard',
      ]),
      getProvince (data) {
        if (data) {
          const findProvince = this.provincesPaysafe.find(item => item.value === data.state)
          if (findProvince) return findProvince.text
        }
        return null
      },
      async submit () {
        this.editForm = false
        // Start Spinner
        this.$loading.show()
        try {
          // =================================
          // Client dans la DB
          // =================================
          // Si il y a un id, le client est dans la DB. Sinon c'est un nouveau client
          if (this.submitForm.id) {
            await ClientsService.update(this.submitForm, this.submitForm.id)
          } else {
            await ClientsService.save(this.submitForm)
          }

          // =================================
          // PaySafe profile
          // =================================
          // let profileId
          // // Construction du profile
          // const newPaySafeProfile = {
          //   cellPhone: this.submitForm.telephone1,
          //   email: this.userInfo.info.username,
          //   firstName: this.submitForm.prenom,
          //   lastName: this.submitForm.nom,
          //   locale: this.submitForm.langue === 'fr' ? 'fr_CA' : 'en_CA',
          //   merchantCustomerId: clientId,
          // }

          // // Si le profile existe, on fait une mise à jour
          // if (this.paySafeProfile.profile) {
          //   profileId = this.paySafeProfile.profile.id
          //   await PaysafeProfileService.update(newPaySafeProfile, profileId)
          // } else {
          //   this.paySafeProfile.profile = newPaySafeProfile
          //   const profileAddress = await PaysafeProfileService.save(this.paySafeProfile.profile)
          //   profileId = profileAddress.id
          // }

          // // ===================================================================================================
          // // PaySafe profile Adresse (Important, sert seulement si il n'y a aucune adresse)
          // // ===================================================================================================
          // // Adresse par défaut
          // this.paySafeProfile.addressDefault.defaultShippingAddressIndicator = true
          // // Si le nickname existe, on crée l'adresse
          // if (this.paySafeProfile.addressDefault.nickName) {
          //   this.paySafeProfile.addressDefault.defaultShippingAddressIndicator = true
          //   await PaysafeProfileAddressService.save(this.paySafeProfile.addressDefault, profileId)
          // }

          // Récupération des infos
          this.getData(false)
        } catch (error) {
          console.log(error)
          // Récupération des infos
          this.getData(false)
        }
      },
      async getData (notification) {
        // Start Spinner
        this.$loading.show()
        this.showAutomaticPaiement = true
        try {
          // Reset data
          this.submitForm = null
          this.paySafeProfile = {
            profile: null,
            addressDefault: {}, // Sert seulement pour la première adresse
            address: [],
            cards: [],
          }

          this.submitForm = await ClientsService.query(this.userInfo.info.username, 'username')
          // console.log('client', this.submitForm)
          // Si l'utilisateur n'est pas dans la base de donnée, on prends les info de du client
          if (!this.submitForm) {
            // On affiche l'édition pour l'utilisateur
            this.editForm = true
            // Montre la confirmation
            this.$notifier.show({ message: this.$i18n.t('profile.snackBar.update_your_profile'), color: 'primary', icon: 'mdi-information', timeout: 5000, position: { top: true, center: true, left: false, right: false } })

            this.submitForm = {
              username: this.userInfo.info.username,
              prenom: this.userInfo.info.firstName,
              nom: this.userInfo.info.name,
              Contrats: null,
            }
          } else {
            // Récupération du profile Paysafe (profile, adresse, carte de crédit)
            const paySafeProfile = await PaysafeProfileService.view(this.submitForm.id)

            // Info PaySafe
            if (paySafeProfile) {
              // Toutes les adresses
              this.paySafeProfile.address = paySafeProfile.addresses
              // Card
              this.paySafeProfile.cards = paySafeProfile.cards
              // Profile
              this.paySafeProfile.profile = paySafeProfile

              // On s'assure que les cartes sont bien entrées dans la table 'Inscription_paiement'
              this.updatePaiementsInscription()
            }
          }

          // // Si le profil n'est pas complet, on envoie un message
          // if (!this.paySafeProfile.addresses || !this.paySafeProfile.addresses.length) {
          //   this.editForm = true
          //   // Montre la confirmation
          //   this.$notifier.show({ message: this.$i18n.t('profile.snackBar.update_your_profile'), color: 'primary', icon: 'mdi-information', timeout: 10000, position: { top: true, center: true, left: false, right: false } })
          // }

          if (notification) this.$notifier.show({ message: this.$i18n.t('profile.snackBar.profile_updated_successfully'), color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })

          this.showAutomaticPaiement = false
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
        try {
          // Stop Spinner
          this.$loading.hide()
          if (notification) this.$notifier.show({ message: this.$i18n.t('profile.snackBar.profile_updated_successfully'), color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      updatePaiementsInscription () {
        // On récupère les cartes relier au client
        // On regarde dans les cartes paysafe si la carte est bien dans la table 'Inscription_paiement'
        for (const item of this.paySafeProfile.cards) {
          const updateCreate = {
            ClientId: this.submitForm.id,
            paiement_token: item.paymentToken,
            nom_titulaire: item.holderName,
            dernier_chiffre: item.lastDigits,
            mois_expiration: item.cardExpiry.month,
            annee_expiration: item.cardExpiry.year,
          }

          const findPaiementsInscriptionFind = this.submitForm.Inscription_paiements.find(x => x.paiement_token === item.paymentToken)

          // Si la carte de paysafe n'est pas dans la table 'Inscription_paiement' on la mets sinon on la mets à jour
          if (findPaiementsInscriptionFind) {
            PaiementsInscriptionService.update(updateCreate, findPaiementsInscriptionFind.id)
          } else {
            PaiementsInscriptionService.save(updateCreate)
          }
        }

        // On supprime les cartes relier au client qui ne sont plus dans la table 'Inscription_paiement'
        for (const item of this.submitForm.Inscription_paiements) {
          const findPaiementsInscriptionFind = this.paySafeProfile.cards.find(x => x.paymentToken === item.paiement_token)
          if (!findPaiementsInscriptionFind) PaiementsInscriptionService.remove(item.id)
        }
      },
      async contractVerification (InscriptionPaiements) {
        try {
          // Si il i a un id de contrat, l'utilisateur à ajouter une nouvelle carte à un contrat dans la console
          if (this.userInfo.newCardContratId) {
            const contrat = await ContratsService.update({ InscriptionPaiementId: InscriptionPaiements.id }, this.userInfo.newCardContratId)
            // Swal
            Swal.fire({
              title: 'Attention !',
              // La carte de crédit finissant par ....0023 a été attribuée au contrat (221) 222-2222
              html: `${this.$i18n.t('profile.swal.text.card_association')} <b>....${InscriptionPaiements.dernier_chiffre}</b> ${this.$i18n.t('profile.swal.text.card_association1')} <b>(${contrat.no.slice(0, 3)}) ${contrat.no.slice(3, 6)}-${contrat.no.slice(6)}</b>`,
              icon: 'success',
            })

            // On efface le id du contrat pour l'ajout d'une nouvelle carte
            this.resetNewCard()
            // Retour au dashboard
            this.$router.push({ name: 'Dashboard' })
          } else {
            // Récupération des infos
            this.getData(false)
          }
        } catch (error) {
          console.log(error)
          // Stop Spinner
          // this.$loading.hide()
          // Récupération des infos
          this.getData(false)
        }
      },
      async cancel () {
        this.editForm = false
        // Start Spinner
        this.$loading.show()
        try {
          this.submitForm = await ClientsService.query(this.userInfo.info.username, 'username')
          // Si l'utilisateur n'est pas dans la base de donnée, on prends les info de oidc
          if (!this.submitForm) {
            this.submitForm = {
              username: this.userInfo.info.username,
              prenom: this.userInfo.info.firstName,
              nom: this.userInfo.info.name,
            }
          }
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      // ======================================
      // Carte de crédit
      // ======================================
      ifCardExpired (expiration) {
        const d = new Date()
        const year = d.getFullYear()
        // Si l'année de la carte de crédit est plus grande que l'année c'est valide
        if (expiration.year > year) return false
        // Si l'année de la carte de crédit est plus petite que l'année c'est invalide (On affichee l'îcone)
        if (expiration.year < year) return true

        // Si l'année est égale, on vérifie le mois
        const month = d.getMonth() + 1
        // Si le mois est plus petit c'est invalide (On affichee l'îcone)
        if (expiration.month < month) return true
        return false
      },
      createNewCard () {
        this.editCreateCardChange = true
        // reset card selected
        this.cardSelected = {
          id: null,
          billingAddressId: this.paySafeProfile.address.length === 1 ? this.paySafeProfile.address[0].id : null, // Si il n'y a q'une adresse, on l'a mets
          holderName: null,
          cardExpiry: {
            month: null,
            year: null,
          },
          cvv: null,
        }

        // Show dialog
        this.showAddEditCardDialog = true
      },
      async submitNewCreditCard () {
        // Start Spinner
        this.$loading.show()
        try {
          // Si c'est une édition et que le client ne modifie pas sa carte, il modifie seulement son adresse de facturation, on ne fait pas de validation
          if (this.cardSelected.id && !this.editCreateCardChange) {
            // On update la carte
            await PaysafeProfileCardService.update(this.cardSelected, this.cardSelected.id, this.paySafeProfile.profile.id, this.submitForm.id)
            // Récupération des infos
            this.getData(false)
          } else {
            // Soit c'est une nouvelle carte, soit le client modifie sa carte
            // ==============================
            // Validation
            // ==============================
            // Validation de la carte et du CVV. Adresse doit corresponde à l'adresse de la carte
            // On trouve les infos de l'adresse de facturation pour la validation
            // const findBillingAddress = this.paySafeProfile.address.find(item => item.id === this.cardSelected.billingAddressId)

            // const cardValidation = {
            //   merchantRefNum: `validation${Date.now()}`,
            //   card: {
            //     cardNum: this.cardSelected.cardNum,
            //     cardExpiry: this.cardSelected.cardExpiry,
            //     cvv: this.cardSelected.cvv,
            //   },
            //   // billingDetails: {
            //   //   street: findBillingAddress.street,
            //   //   city: findBillingAddress.city,
            //   //   state: findBillingAddress.state,
            //   //   country: findBillingAddress.country,
            //   //   zip: findBillingAddress.zip,
            //   // },
            //   description: 'Validation de carte',
            // }

            // await PaysafeProfileCardService.validation(cardValidation)
            // ==============================
            // Ajout de la carte au profile
            // ==============================
            if (this.cardSelected.id) await PaysafeProfileCardService.update(this.cardSelected, this.cardSelected.id, this.paySafeProfile.profile.id, this.submitForm.id)
            if (!this.cardSelected.id) {
              const info = await PaysafeProfileCardService.save(this.cardSelected, this.paySafeProfile.profile.id, this.submitForm.id)
              // Si le client à ajouter une carte de crédir dans le dashboard, on ajoute la carte au contrat
              this.contractVerification(info.inscriptionPaiement)
            }
          }
          // On ferme le dialog
          this.showAddEditCardDialog = false
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async editCard (card) {
        // Par défaut le client ne change pas sa carte. Seulement son adresse de facturation
        this.editCreateCardChange = false

        // Reset card
        this.resetCard(card)

        // On ouvre le dialog
        this.showAddEditCardDialog = true
      },
      async changeEditCard (event) {
        // Si l'utilisateur annule la modification de sa carte, on réinitialise sa carte
        if (!event) {
          // Start Spinner
          this.$loading.show()
          try {
            const card = await PaysafeProfileCardService.view(this.cardSelected.id, this.paySafeProfile.profile.id, this.submitForm.id)

            // Reset card
            this.resetCard(card)

            // Stop Spinner
            this.$loading.hide()
          } catch (error) {
            console.log(error)
            // Stop Spinner
            this.$loading.hide()
          }
        }
      },
      resetCard (card) {
        this.cardSelected = {
          id: card.id,
          lastDigits: card.lastDigits,
          billingAddressId: card.billingAddressId,
          holderName: card.holderName,
          cardExpiry: {
            month: card.cardExpiry.month,
            year: card.cardExpiry.year,
          },
          cvv: null,
        }
      },
      async deleteCard (card) {
        // Start Spinner
        this.$loading.show()
        try {
          // On regarde si la carte est relier à des contrats
          const inscriptionPaiement = await PaiementsInscriptionService.query(card.paymentToken, 'paiement_token')

          // Suppression du paiement token
          if (inscriptionPaiement) {
            // Si contrat, on regarde si les contrats sont tous actif, intégrité ou suspendu. Si il y a un contrat actif (1), intégrité (6) ou suspendu (7), le client ne peut pas supprimer sa carte car il y a un contrat. Le type est payable par carte de crédit
            if (inscriptionPaiement.Contrats.find(item => (item.statut === 1 || item.statut === 6 || item.statut === 7) && item.type === 2)) {
              // Swal
              Swal.fire(
                'Attention !',
                `${this.$i18n.t('profile.swal.text.cant_delete_card_contract')}`,
                'error',
              )

              // Stop Spinner
              this.$loading.hide()
              return
            }

            // Si il y a des commandes en cours, on ne peut pas supprimer une la carte
            if (inscriptionPaiement.Commandes.length) {
              // Swal
              Swal.fire(
                'Attention !',
                `${this.$i18n.t('profile.swal.text.cant_delete_card_order')}`,
                'error',
              )

              // Stop Spinner
              this.$loading.hide()
              return
            }
          }

          // Supression de la carte
          // On supprime l'inscription paiement si il y en a un
          if (inscriptionPaiement) await PaiementsInscriptionService.remove(inscriptionPaiement.id)
          // On supprime la carte
          await PaysafeProfileCardService.remove(card.id, this.paySafeProfile.profile.id, this.submitForm.id)

          // Récupération des infos
          this.getData(false)
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      // ======================================
      // Address
      // ======================================
      addNewAddress (card) {
        this.addressSelected = {
          city: null,
          country: null,
          defaultShippingAddressIndicator: null,
          nickName: null,
          state: null,
          status: null,
          street: null,
          zip: null,
        }
        // Show dialog
        this.showAddEditAddressDialog = true
      },
      editAddress (address) {
        this.addressSelected = address
        // Show dialog
        this.showAddEditAddressDialog = true
      },
      async deleteAddress (address) {
        // Start Spinner
        this.$loading.show()
        try {
          // On regarde si l'adresse est relié à une carte
          const findCardAddress = this.paySafeProfile.cards.find(item => item.billingAddressId === address.id)

          // Une carte contient l'adresse. On ne peut pas supprimer l'adresse
          if (findCardAddress) {
            // Swal
            Swal.fire(
              'Attention !',
              `${this.$i18n.t('profile.swal.text.cant_delete_address')}`,
              'error',
            )

            // Stop Spinner
            this.$loading.hide()
            return
          }

          // Supression de la carte
          await PaysafeProfileAddressService.remove(address.id, this.paySafeProfile.profile.id)

          // Récupération des infos
          this.getData(false)
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async submitNewEditAddress () {
        // Start Spinner
        this.$loading.show()
        try {
          // Si l'adresse à un id c'est un update
          if (this.addressSelected.id) {
            await PaysafeProfileAddressService.update(this.addressSelected, this.addressSelected.id, this.paySafeProfile.profile.id)
          } else {
            // Si c'est une nouvelle adressse, on regarde si il y a un profile
            if (this.paySafeProfile.profile) {
              // Il y a un profile, on crée l'adresse
              await PaysafeProfileAddressService.save(this.addressSelected, this.paySafeProfile.profile.id)
            } else {
              // Il y a pas de profile. On crée le profile et ensuite l'adresse
              // Construction du profile
              const newPaySafeProfile = {
                email: this.submitForm.email,
                firstName: this.submitForm.prenom,
                lastName: this.submitForm.nom,
                locale: this.submitForm.langue === 'fr' ? 'fr_CA' : 'en_CA',
                merchantCustomerId: this.submitForm.id,
              }
              const profile = await PaysafeProfileService.save(newPaySafeProfile)
              await PaysafeProfileAddressService.save(this.addressSelected, profile.id)
            }
          }

          // Hide dialog
          this.showAddEditAddressDialog = false

          // Récupération des infos
          this.getData(false)
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
