import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/paysafe/profile/address')
    },
    save (profileAddress, profileId) { // Création d'une nouvelle entrée
        return api().post(`/paysafe/profile/address/${profileId}`, profileAddress)
    },
    view (profileAddressId, profileId) { // Voir une entrée spécifique
        return api().get(`/paysafe/profile/address/${profileAddressId}/${profileId}`)
    },
    update (profileAddress, profileAddressId, profileId) { // Mise à jour d'une entrée spécifique
        return api().put(`/paysafe/profile/address/${profileAddressId}/${profileId}`, profileAddress)
    },
    remove (profileAddressId, profileId) { // Suppression d'une entrée spécifique
        return api().delete(`/paysafe/profile/address/${profileAddressId}/${profileId}`)
    },
}
